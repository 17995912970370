import { createApp } from 'vue'
import App from './App.vue'
import { createPinia } from 'pinia'    //pinia  数据状态管理  类vuex
const pinia = createPinia() 
import router from './router'
import 'normalize.css'   //初始化全局样式插件    
import './assets/css/globalCss.scss'
import ViewUIPlus from 'view-ui-plus'
import 'view-ui-plus/dist/styles/viewuiplus.css'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'  // pinia 数据持久化
pinia.use(piniaPluginPersistedstate)
import moment from 'moment';
moment.locale('zh-cn');
import "echarts";
import ECharts from 'vue-echarts'




const app = createApp(App)
app.use(pinia)
app.use(ViewUIPlus)
app.use(router)

//全局时间过滤器
app.config.globalProperties.$formateDate =(timestamp)=> {
  return moment(timestamp).format('YYYY-MM-DD HH:mm:ss')
}
//全局表格数组展示过滤器
app.config.globalProperties.$handeData =(data)=> {
  let result = '';
  if(data.length >0){
    data.forEach(item => {
      result += item + ' '; // 将每一项添加到字符串中，并在每一项之间添加空格
      });
  }else{
    result = '/'
  }
 return result
}


app.component('v-chart', ECharts)
app.mount('#app')
